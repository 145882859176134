















































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { UserUploadService } from '@/services/user-uploading';
import { DialogService } from '@/services/common/dialog.service';
import { cloneDeep } from 'lodash';
import { uuid } from '@/services/utils';

@Component
export default class OpenButtonDialog extends Vue {
  public checkboxStatus: boolean = false;
  private data!: any;
  private disabled: boolean = false;
  private loading: boolean = false;
  private switchvalue: boolean = false;
  private syncStatus:boolean = true;
  private uploadStatus:boolean = true;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;



  public async created(): Promise<void> {
    await this.getsynchronization();
  }

  // 默认获取是否开启同步
  public async getsynchronization() {
    var params:{} = this.data.fieldType == 'syncPharma' ? {type:'syncPharma'} : {type:'syncOnco'}
    const res = await this.userUploadService.getsynchronization(params);
    // 开关状态res.status  0关1开
    this.switchvalue = res.status == 1 ? true : false
    // 同步状态res.result : 1同步中 2同步完成  0未同步
    this.syncStatus = res.result == 1 ? true : false
    // 上传状态res.uploadStatus: 1上传中  0和2没有上传
    this.uploadStatus = res.excelResult == 1 ? true : false
    console.log('01:',res.status,'012未中无:',res.result,'012未中无:',res.excelResult);
    console.log('this.switchvalue开关',this.switchvalue,'this.syncStatus同步状态',this.syncStatus,'this.uploadStatus上传状态',this.uploadStatus);
  }

  //保存
  private async submit(val:boolean) {
    console.log('submit',val);
    // this.loading = true;
    this.disabled = true;
    var params:{} = this.data.fieldType == 'syncPharma' ? {type:'syncPharma'} : {type:'syncOnco'}
    const res = await this.userUploadService.getsynchronization(params);
    console.log('res.uploadStatus',res.uploadStatus);
    if(res.excelResult !== 1){
      try {
          const params: any = {
            type: this.data.fieldType, // 这个是pharma 还是oconlogy。
            button: this.switchvalue?'1':'0', // 这个是当前的switch是开启还是关闭。
            soonFlag: val // 这个是判断是不是立即同步，ture，就是立即同步。
          };
        const res = await this.userUploadService.synchronization(params);
        this.$notify.success({
          title: '同步操作',
          message: '同步操作保存成功'
        });
        this.dialog.close(CloseStatus.confirm, this.data.key);
      } catch {
        //   this.$notify.error({
        //   title: '错误',
        //   message: ''
        // });
      }
    }else{
      this.$message.error({
        message:'当前有用户执行上传操作，请稍后重试！'
      })
    }
    // this.loading = false;
    this.disabled = false;
  }

  //保存并立即执行
//   private async submit(val:boolean) {
//     console.log('saveAndexecute');

//     //this.data.openStatus是传进dialog的data是启用开关的true和false
//     console.log('this.data', this.data.openStatus);
//     let SwitchButton = '';
//     try {
//       const params: any = {
//         type: this.data.fieldType, // 这个是pharma 还是oconlogy。
//         button: '1', // 这个是当前的switch是开启还是关闭。
//         soonFlag: val // 这个是判断是不是立即同步，ture，就是立即同步。
//       };
//       const res = await this.userUploadService.synchronization(params);
//       this.$notify({
//         title: '立即同步',
//         message: '立即同步操作成功',
//         type: 'success'
//       });
//       this.dialog.close(CloseStatus.confirm, this.data.key);
//     } catch {}
//   }

//   private async synchronization(val: any) {
//     console.log('val', val);
//     let SwitchButton = '';
//     if (val == true) {
//       //开是1
//       SwitchButton = '1';
//       const key = uuid();
//       const res = await this.dialog.open(
//         '同步控制',
//         openRightNow,
//         {
//           openStatus: val,
//           fieldType: 'syncOnco',
//           key
//         },
//         {
//           width: 400,
//           height: 160
//         }
//       );
//       if (res.action !== CloseStatus.confirm) {
//         this.switchvalue = false;
//         // const params: any = {
//         //   type: 'syncOnco', // 这个是pharma 还是oconlogy。
//         //   button: !SwitchButton, // 这个是当前的switch是开启还是关闭。
//         //   soonFlag: false // 这个是判断是不是立即同步，ture，就是立即同步。
//         // };
//         // const res = await this.userUploadService.synchronization(params);
//       }
//     } else {
//       //关是0
//       SwitchButton = '0';
//       const params: any = {
//         type: 'syncOnco', // 这个是pharma 还是oconlogy。
//         button: SwitchButton, // 这个是当前的switch是开启还是关闭。
//         soonFlag: false // 这个是判断是不是立即同步，ture，就是立即同步。
//       };
//       const res = await this.userUploadService.synchronization(params);
//     }
//     // this.getList();
//   }

  private async cancle() {
    console.log('cancle');
    // this.$notify({
    //       title: '取消',
    //       message: '取消当前操作',
    //       type: 'warning'
    //     });
    this.dialog.close(CloseStatus.cancel);
  }
}
